import { graphql, useStaticQuery, Link } from "gatsby";
import React, { useState } from "react";

function Header() {
  const [isExpanded, toggleExpansion] = useState(false);
  const { site } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <nav className="w-full items-center max-w-5xl mx-auto justify-between p-4 px-5 md:py-8 md:px-10">
      <div className="flex">
        <div className="flex flex-shrink-0 items-center ml-3">
          <a class="text-2xl sm:text-3xl text-gray-900 font-light" href="#">Alex Slade</a>
        </div>

        <div className="flex flex-grow items-center w-auto">
          <div className="ml-auto">
            <a class="block inline-block sm:text-lg md:text-xl p-5 hover:underline" href="mailto:alex@aslade.com">Contact</a>
            {/* <a class="block inline-block mt-1 mr-5 text-blue-900 hover:text-blue-700 sm:text-lg md:text-xl" href="#">About</a> */}
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Header;
