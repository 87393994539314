import PropTypes from "prop-types";
import React from "react";

import Header from "./header";
import bg from "../images/background.svg"

function Layout({ children }) {
  return (
    <div className="flex flex-col font-sans min-h-screen text-gray-900 antialiased hero-bg">
      <Header />

      <main className="flex flex-col flex-1 max-w-5xl mx-auto px-4 py-8 md:p-8 w-full">
        {children}
      </main>

      <footer className="0">
        <nav className="flex justify-between max-w-4xl mx-auto p-4 md:p-8 text-sm">
          <p className="text-white">
          </p>

          <p>
          </p>
        </nav>
      </footer>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
